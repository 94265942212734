import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import highSpeedFibreIcon from "../../images/student-accommodation/tab/highspeed_fibre_icon.svg";
import experienceDiffIcon from "../../images/student-accommodation/tab/exp_diff.svg";
// import intWifiMeshIcon from "../../images/student-accommodation/tab/intwifimesh.svg";

export default function SpeedTab() {

  return (
    <div className="tab-connection">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Don't be stuck in rush hour"}
            </div>
            <div className="tab-desc">
              {"Residents can choose internet speeds of up to 1Gbps on our FTTx network"}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-5">
          <Col xs={12} md>
            <img src={highSpeedFibreIcon} className="tab-img mr-3" alt="high speed fibre" />
            <div className="tab-title">
              {"High-speed Fibre"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"We operate an extensive fibre network to metropolitan centres in the Asia-Pacific. Our uncontended fibre network runs at speeds of at least 10 Gbps per second to the building"}
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs={12} md>
            <img src={experienceDiffIcon} className="tab-img mr-3" alt="experience difference" />
            <div className="tab-title">
              {"Experience the difference"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"With a purpose-built network and no legacy systems, we utilise the latest carrier equipment to deliver the best performance."}
            </div>
          </Col>
        </Row>

        {/* <Row className="my-5">
          <Col xs={12} md>
            <img src={intWifiMeshIcon} className="tab-img mr-3" alt="Integrated Wi-Fi Mesh" />
            <div className="tab-title">
              {"Integrated Wi-Fi Mesh"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"From pre-protection against threats through to monitoring access points for peak performance, we manage each and every aspect of the user experience."}
            </div>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
}