import React from "react"
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import OtherMIESolutions from "../components/sections/hubs/other-MIE-hub";

import seamlessIcon from "../images/student-accommodation/info/seamless.svg";
import universityIcon from "../images/student-accommodation/info/university_approved.svg";
import reportingAnalyticsIcon from "../images/student-accommodation/info/reporting_analytics.svg";

import ConnectionTab from "../components/tabs/connection";
import SpeedTab from "../components/tabs/speed";
import SupportTab from "../components/tabs/support";
import ManagementTab from "../components/tabs/management";

import unilodgeIcon from "../images/mie/case-study/unilodge.svg";

import residentialIcon from "../images/other-mie/residential.png";
import hotelIcon from "../images/other-mie/hotels.png";
import publicWifiIcon from "../images/other-mie/public_wifi.png";
import coworkingIcon from "../images/other-mie/coworking.png";

import bgImg from "../images/headers/subheader_blue.svg";
import bannerImage from "../images/box-headers/studentaccom.jpg";
import {getCategoryId} from "../utils/categories";
const categoryId = getCategoryId("Student Accommodation");

const StudentAccommodation = () => (
  <Layout title="Student Accommodation">
    <SEO title="Student Accommodation - Internet and Wi-Fi Platform - VostroNet">
      <meta name="description" content="VostroNet are experts at delivering Wi-Fi and Internet to Student Accommodation with 25,000+ connections. Empowering building managers with our Q2 software." />
    </SEO>
    <Banner
      subpage
      bgImg={bgImg} data={{
        title: {
          name: "Student Accommodation",
        },
        subtitles: [
          {
            name: `Students are demanding`,
            className: "fw-400",
          },
          {
            name: `We are experts at delivering lightning fast internet to 25,000+ connections in student accommodation`,
            className: "banner-text-small ",
          },
        ],
        img: bannerImage,
      }}
      hideScrollButton
      customButtons={(<>
        <Container>
          <Row>
            <Col xs="auto">
              <Button variant="green" className="btn-banner mb-3" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-datasheet-student.pdf">
                <i className="fas fa-file-pdf mr-2" />
                {"Student Datasheet"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-dark" className="btn-banner-secondary" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-solutions-student.pdf">
                <i className="fas fa-binoculars mr-2" />
                {"Quick overview"}
              </Button>
            </Col>
          </Row>
        </Container>
      </>)} />
    <div className="general py-5">
      <Container>
        <Row>
          <Col>
            <div className="title mb-3">
              {"End-to-End"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-4">
              {"VostroNet deploys specialised infrastructure and software to Student Accommodation developments across the Asia-Pacific. We install and operate our own network, from the signal in student rooms, the software authenticating the user, the fibre connecting the building and the connection to content providers. We guarantee performance."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <InformationSection data={{
      title: "Smarter way to operate",
      subtitle: "Students are a unique user class, requiring fast, reliable internet with minimal administration. Our innovation lies with our Q2 Software Platform, empowering building managers with the tools they need to manage their students' internet experience.",
      subData: [
        {
          title: "Seamless Integration",
          subtitle: "Simplify the onboarding process with direct integration with the leading property management systems such as StarRez and HiRUM",
          img: seamlessIcon,
        },
        {
          title: "University Approved",
          subtitle: "Students can use their university credentials to authenticate onto our network, ensuring a streamlined campus experience",
          img: universityIcon,
        },
        {
          title: "Reporting and Analytics",
          subtitle: "Understand your users with customisable reporting and useful data analytics. Have key information available in real-time and on-demand",
          img: reportingAnalyticsIcon,
        },
      ],
    }} />

    <Container className="py-5 tab-section">
      <Row>
        <Col>
          <Tabs defaultActiveKey="connection" id="SA-tab-section">
            <Tab eventKey="connection" title="Connection">
              <ConnectionTab />
            </Tab>
            <Tab eventKey="speed" title="Speed">
              <SpeedTab />
            </Tab>
            <Tab eventKey="support" title="Support">
              <SupportTab />
            </Tab>
            <Tab eventKey="management" title="Management">
              <ManagementTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>

    <CaseStudyContent data={{
      link: "https://cdn.vostro.cloud/content/casestudy-student-unilodge.pdf",
      title: "Featured case studies",
      desc: "Trusted by Australia's largest student accommodation Provider...",
      img: null,
      sideNote: "VostroNet closely partners with Providers for high speed internet and successful outcomes",
    }} />
    <Blog category={categoryId} />
    <OtherMIESolutions data={[
      {
        label: "Residential MDU",
        link: "/residential-fibre-network/",
        img: residentialIcon,
      },
      {
        label: "Hotels",
        link: "/hotel/",
        img: hotelIcon,
      },
      {
        label: "Public Wi-Fi",
        link: "/public-wifi/",
        img: publicWifiIcon,
      },
      {
        label: "Co-Working",
        link: "/co-working/",
        img: coworkingIcon,
      },
    ]} />
  </Layout>
)

const CaseStudyContent = ({ data }) => {
  const { link, desc, title } = data;

  return (
    <div className="case-study">
      <Container className="py-5">
        <a href={link} target="_blank" rel="noopener" className="font-white">
          <Container fluid>
            <Row>
              <Col>
                <div className="case-title mb-3">
                  {"Read our case studies"}
                </div>
              </Col>
            </Row>
            <Row className="modified-row">
              <Col xs={12} lg={6} className="no-padding">
                <div className="featured-case case">
                  <div className="title mb-4">
                    {title}
                  </div>
                  <div className="case-desc mb-4">
                    {desc}
                  </div>
                  <img src={unilodgeIcon} alt="unilodge" className="case-img mr-auto" />
                </div>
              </Col>
              <Col xs={12} lg={3} className="no-padding">
                <div className="featured-case px-0 py-1">
                  <div className="case-desc side-note text-center p-3">
                    <div className="mb-2">
                      {`"The portal allows us to quickly and painlessly connect our residents. It is a fantastic tool for managing internet in our building."`}
                    </div>
                    <div className="italic">
                      {"Matthew, Building Manager"}
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={3} className="no-padding">
                <div className="featured-case p-0">
                  <div className="case-desc text-center side-note">
                    {"VostroNet deploys an innovative managed Wi-Fi solution to UniLodge sites across Australia and New Zealand"}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </a>
      </Container>
    </div>
  );
}

export default StudentAccommodation;
