import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import bestOfBestIcon from "../../images/student-accommodation/tab/bestofthebest.svg";
import availableAnywhereIcon from "../../images/student-accommodation/tab/available_everywhere.svg";
import keepingUsersIcon from "../../images/student-accommodation/tab/keeping_users.svg";


export default function ConnectionTab() {

  return (
    <div className="tab-connection">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Everybody needs good Wi-Fi"}
            </div>
            <div className="tab-desc">
              {"Good Wi-Fi is much more than a decent connection to a building. We know how to get the right equipment into the right places to provide the internet experience that users demand, and are experts at keeping them connected and protected."}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-4">
          <Col>
            <img src={bestOfBestIcon} alt="best of the best icon" className="tab-img mr-3" />
            <div className="tab-title">
              {"We deploy the best of the best"}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="tab-desc">
              {"We are the largest Wi-Fi carrier for the HPE Aruba in Australia and New Zealand. Actively managing thousands of W-Fi access points. VostroNet delivered the first Wi-Fi 6 (802.11ax) connected student accommodation site in Australia"}
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <img src={availableAnywhereIcon} className="tab-img mr-3" alt="avaiable anywhere icon" />
            <div className="tab-title">
              {"It's available everywhere"}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="tab-desc">
              {"A lot goes into our Wi-Fi design; we use heat mapping and industry best practices to ensure coverage everywhere: from the room to the rooftop and everything in between."}
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <img src={keepingUsersIcon} className="tab-img mr-3" alt="keeping users online" />
            <div className="tab-title">
              {"Keeping users online and safe"}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="tab-desc">
              {"From pre-protection against threats through to monitoring access points for peak performance, we manage each and every aspect of the user experience."}
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
}