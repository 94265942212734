import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "gatsby";

const OtherMIEHub = ({data = []}) => {
  return (
    <div className="py-5 other-mie-hub bg-grey">
      <Container>
        <Row>
          <Col>
            <div className="title mb-4">
              {"Other Internet Environment Solutions"}
            </div>
          </Col>
        </Row>
        <Row>
          {data.map(({label, link, img}) => {
            return (<Col xs={12} md={6} lg={3} key={link} className="mb-3">
              <Link to={link} className="solution p-3 block-center height-100p">
                <img src={img} alt={label} className="mb-3 solution-img block-center" />
                <div className="label mt-3 block-center text-center">
                  {label}
                </div>
              </Link>
            </Col>);
          })}
        </Row>
      </Container>
    </div>
  );
}

export default OtherMIEHub;