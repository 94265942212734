import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import allocateUserIcon from "../../images/student-accommodation/tab/allo_users.svg";
import studentCareIcon from "../../images/student-accommodation/tab/studentcare.svg";
import smartRoomIcon from "../../images/student-accommodation/tab/smartroom.svg";

export default function ManagementTab() {

  return (
    <div className="tab-management">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Manage users and devices via VostroNet's advanced software platform"}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-4">
          <Col>
            <img className="tab-img mr-3" src={allocateUserIcon} alt="allocate users" />
            <div className="tab-title">
              {"Allocate users"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Add and remove users to the network via an assigned management portal. Set dates for when users leave their apartment for the internet to be disabled for that room, ultimately saving your building money."}
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <img className="tab-img mr-3" src={studentCareIcon} alt="Student Care" />
            <div className="tab-title">
              {"Student Care"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Maintain and monitor the well-being of your student residents via VostroNet's advanced data analytics. Prompt students to attend pizza evenings and use data to identify when students haven't left their rooms for a significant period"}
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <img className="tab-img mr-3" src={smartRoomIcon} alt="Smart Rooms" />
            <div className="tab-title">
              {"Smart Rooms"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Stay ahead of the market with a smart-room compatible network provider. We facilitate the interconnectivity of devices and appliances to a centralised managed system."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}