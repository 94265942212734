import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import nineNineIcon from "../../images/student-accommodation/tab/99percent.svg";
import escalationIcon from "../../images/student-accommodation/tab/escalationchannel.svg";
import multichatIcon from "../../images/student-accommodation/tab/multichat.svg";

export default function SupportTab() {

  return (
    <div className="tab-support">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Quality Support"}
            </div>
            <div className="tab-desc">
                {"Customer care shouldn't be limited to the phone. Our users can engage with us via chat, phone calls, emails or social media messaging. Having real-time access to help, 24/7, is the standard that users demand."}
              </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={12} md={4}>
            <Row>
              <Col xs={12} md={4}>
                <img className="img" src={nineNineIcon} alt="99% Customer Satisfaction" />
              </Col>
              <Col>
                <div className="tab-desc align-middle mb-3">
                  <div>{"99% Customer Satisfaction"}</div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={12} md={4}>
                <img className="img" src={escalationIcon} alt="Clear Escalation Channel" />
              </Col>
              <Col>
                <div className="tab-desc align-middle mb-3">
                  <div>{"Clear Escalation Channel"}</div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={12} md={4}>
                <img className="img" src={multichatIcon} alt="multilingual Chat" />
              </Col>
              <Col>
                <div className="tab-desc align-middle mb-3">
                  {"multilingual Chat"}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}